import React, { useState } from "react";

export default function PhotoUpload(props) {
  const [uploadImageError, setUploadImageError] = useState(false);
  const [imagesArr, setImagesArr] = useState([]);

  const onChangeHandler = (e) => {
    let files = e.target.files;
    if (files.length > 25) {
      props.showPopup("Please select upto 25 images only.");
      setImagesArr([]);
      return false;
    }
    setImagesArr(e.target.files);
   let result = uploadImage(e.target.files)
   if(!result){
    setImagesArr([])
   }
  };

  const uploadImage = (photos) => {
    // e.preventDefault();
    // let files = e.target.files;
    if (photos.length < 1) {
      setUploadImageError(true);
      return false;
    } else {
      setUploadImageError(false);
    }
    if (photos.length > 25) {
      props.showPopup("Please select upto 25 images only.");
      return false;
    }

    // let imageArrTemp = [];
    // for (let i = 0; i < photos.length; i++) {
    //   const file = photos[i];

    //   imageArrTemp.push(file);
    // }

    // let totalImages = [...photos, ...imageArrTemp];
    let totalImages = photos;
    if (totalImages && totalImages.length > 25) {
      props.showPopup("You can upload up to 25 images at once");
      return false;
    }

    props.uploadImages(totalImages);
  };
  return (
    <>
      <div className="photupload_banner">
        <div className="photocontainer">
          <img src="./assets/images/img-1.jpg" className="mkb_banner" alt="" />
        </div>
      </div>

      <div className="upload_picturesection">
        <div className="photocontainer">
          <div className="next_flex">
            <div>
              <div className="uploadimg_div">
                <input
                  id="uploadingImage"
                  type="file"
                  multiple
                  accept="image/jpg,image/png,image/jpeg"
                  onChange={(e) => onChangeHandler(e)}
                />
                <h4>
                  <img src="./assets/images/cloud.svg" className="" alt="" />{" "}
                  <span>Upload Pictures</span>
                </h4>
              </div>
              <div className="upload_detail">
                <h5>Up to 25 images</h5>
                {uploadImageError && (
                  <h5 className="select_image">Please Select Images</h5>
                )}

                <p>
                  Please upload images of yourself listening to PM Modi’s Mann
                  ki Baat with friends and family.
                </p>
              </div>
            </div>

            {/* <div>
              <a onClick={(e) => uploadImage(e)} href="" className="next_photo">
                Next
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
