import React, {useState, useEffect} from "react";

export default function Gallery(props) {
  const [photos, setPhotos] = useState([]);

  const deselectSelectedPhoto = (index) => {
    props.updateSelectedPhotos(index);
  };
  useEffect(() => {
    if (props.allPhotosSelected.length > 0) {
      setPhotos(props.allPhotosSelected);
    } else {
      props.falsePhotoSelected();
    }
  }, [props.allPhotosSelected]);
  return (
    <>
      <div className="form_bg">
        <div className="photocontainer">
          <div className="gallery_bg">
            <ul className="gallery_list">
              {photos &&
                photos.length > 0 &&
                photos.map((item, index) => {
                  return (
                    <li key={index}>
                      <img src={URL.createObjectURL(item)} className="img_ban" />
                      <img
                      onClick={() => deselectSelectedPhoto(index)}
                      src="/assets/images/crosscircle.svg"
                      className="cross_circle"
                      alt=""
                    />
                    </li>
                  );
                })}
              {/* <li>
                <img src="./assets/images/img-1.jpg" alt="" />
              </li>

              <li>
                <img
                  src="https://img.freepik.com/free-photo/wide-angle-shot-single-tree-growing-clouded-sky-during-sunset-surrounded-by-grass_181624-22807.jpg"
                  alt=""
                />
              </li>

              <li>
                <img src="./assets/images/img-1.jpg" alt="" />
              </li>

              <li>
                <img src="./assets/images/img-1.jpg" alt="" />
              </li>

              <li>
                <img src="./assets/images/img-1.jpg" alt="" />
              </li>

              <li>
                <img src="./assets/images/img-1.jpg" alt="" />
              </li>

              <li>
                <img src="./assets/images/img-1.jpg" alt="" />
              </li> */}
            </ul>
          </div>
          <a  onClick={() => props.save()} href="#" className="next_photo2">
            Next
          </a>
        </div>
      </div>
    </>
  );
}
