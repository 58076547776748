import React, { useEffect, useState } from "react";

export default function Loader(props) {
    return (
        <>
            <div className="preloader_bg">
                <div className="preloader_img">
                    <img src="./assets/images/loader.png" className="corss_thank" alt="" />
                    {props?.text && <h4>{props?.text}</h4>}
                </div>
            </div>
        </>
    )
}