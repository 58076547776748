import React from "react";

export default function Thankyou(props) {
    return (
        <>
            <div className="thankyou_bg">
                <div className="thankyou_content">
                <img onClick={() => props.close()} src="./assets/images/crosstag.svg" className="corss_thank" alt="" />
                    <img src="./assets/images/refinvite.svg" className="thankyou" alt="" />
                    <h6>Your photo{props?.images && props?.images.length > 1 ? 's have' : ' has'} been uploaded</h6>
                    <h5>Thank you for making this event a grand success.</h5>
                </div>
            </div>
        </>
    )
}