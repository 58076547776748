import React, { useEffect, useState } from "react";
import Popup from "./Popup";
import AWS from "aws-sdk/global";
import S3 from "aws-sdk/clients/s3";
import {
  AjaxRequest,
  AjaxRequestForConstituency,
  doRequest,
  parseURLParams,
} from "./helper";
import $ from "jquery";
import { v4 as uuidv4 } from "uuid";
import CoutryComponent from "./CountryComponent";
import Loader from "./Loader";
import Thankyou from "./Thankyou";
import PopUpMessage from "./PopupMessage";
import { countryList } from "./helper";

const errorStyle = {
  color: "red",
};
export default function Form(props) {
  const bucketname = "js-sdk-bucket";
  AWS.config.region = "ap-south-1"; // Region
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: "ap-south-1:84027705-475d-4ca1-bac8-b2eb7407ae9f",
  });
  const bucket = new AWS.S3({
    params: {
      Bucket: bucketname,
    },
  });

  const [stateval, setStateVal] = useState([]);
  const [cityval, setCityVal] = useState([]);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [state, setState] = useState("");
  const [consti, setConsti] = useState("");
  const [eventLocation, setEventLocation] = useState({});
  const [lat, setLat] = useState();
  const [long, setLong] = useState();
  const [changecode, setChangecode] = useState("91");
  const [location_status, setLocation_status] = useState("success");
  const [phoneError, setPhoneError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mobilelengthError, setMobilelengthError] = useState(false);
  const [showPopupMessage, setShowPopupMessage] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [completed, setCompleted] = useState(false);
  const [userDetails, setUserDetails] = useState({
    mobile: "",
    name: "",
    designation: "",
    benname: "",
    benmobile: "",
    benscheme: "",
    state: "",
    constituency: "",
    state_id: "",
    kar_state: "",
    kar_constituency: "",
  });
  const [deviceId, setDeviceId] = useState("");
  const [deviceAppVersion, setDeviceVersion] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("India");
  const [showStateConsti, setShowStateConsti] = useState(false);

  const getStateList = async () => {
    // props.startLoading();
    setLoading(true);
    var formdata = new FormData();
    formdata.append("action", "getstatelist");
    formdata.append("deviceid", "b7993a53-6f5a-9d2e-eaa5-ebec5113303c");
    formdata.append(
      "token",
      "W5M8sZX1SQImZ1dZzNVTO3VP22H6x4ArRli0rIQ3TwC9kPuxOS/MES3kpC5bRyX6"
    );

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("https://api.narendramodi.in/mlapiv1", requestOptions)
      .then((response) => response.json())
      .then((result) => setStateVal(result.data))
      .catch((error) => console.log("error", error));
    setLoading(false);
  };

  useEffect(() => {
    try {
      let obj = {};
      window.gtag("event", "Image Upload successfully", obj);
    } catch (error) { }
    getStateList();
    getLocation();
  }, []);

  const closePopupMessage = () => setShowPopupMessage(false);
  const showPopup = (msg) => {
    setShowPopupMessage(true);
    setPopupMessage(msg);
  };

  const getConstituencyList = async (state_id) => {
    var element = $("#state").find("option:selected");
    var myTag = element.attr("data-name");

    var formdata = new FormData();
    formdata.append("action", "getconstituencylist");
    formdata.append("stateid", state_id ? state_id : myTag);
    formdata.append("deviceid", "b7993a53-6f5a-9d2e-eaa5-ebec5113303c");
    formdata.append(
      "token",
      "W5M8sZX1SQImZ1dZzNVTO3VP22H6x4ArRli0rIQ3TwC9kPuxOS/MES3kpC5bRyX6"
    );

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("https://api.narendramodi.in/apiv2", requestOptions)
      .then((response) => response.json())
      .then((result) => setCityVal(result.data))
      .catch((error) => console.log("error", error));
  };

  // useEffect(() => {
  //   var url_string = window.location.href; //window.location.href
  //   var url = new URL(url_string);
  //   var userid = url.searchParams.get("userid");

  //   var androidORIos = url.searchParams.get("appversion");
  //   let localDeviceId = localStorage.getItem("device_id");
  //   let newDeviceId = uuidv4();
  //   localStorage.setItem(
  //     "device_id",
  //     userid ? userid : localDeviceId ? localDeviceId : newDeviceId
  //   );
  //   localStorage.setItem("appversion", androidORIos);
  // }, []);
  function hasAlphabet(inputString) {
    // Use a regular expression to match only alphabet characters
    const regex = /^[a-zA-Z]+$/;
    return regex.test(inputString);
  }
  const submitHandler = async (e) => {
    setLoading(true);
    // props.startLoading(true);
    e.preventDefault();
    let numOfError = 0;
    // if (!mobile) {
    //   numOfError += 1;
    //   setPhoneError(true);
    // } else {
    //   setPhoneError(false);
    // }

    let isNumber = /^[0-9]+$/

    if(mobile.match(isNumber) == null) {
      showPopup('Please enter a valid mobile number.');
      setLoading(false)
      return false
    }

console.log('mobile' + hasAlphabet(mobile))
    if(hasAlphabet(mobile)){
      showPopup('Please enter a valid mobile number.');
      setLoading(false)
      return false
    }



    if (selectedCountry == "India") {
      if (mobile && (mobile.length < 10 || mobile.length > 10)) {
        numOfError += 1;
        setMobilelengthError(true);
      } else {
        setMobilelengthError(false);
      }
    }
    if (numOfError >= 1) {
      setLoading(false);
      return false;
    }

    for (let i = 0; i < props.uploadedImagesArr.length; i++) {
      let formdata = {
        name,
        mobile: mobile && mobile,
        state,
        constituency: consti,
        lat: lat && lat.toString(),
        long: long && long.toString(),
        device_id: localStorage.getItem("device_id"),
        platform:
          localStorage.getItem("appversion") != "null"
            ? localStorage.getItem("appversion")
            : "pwa",
        location_status,
        countryName: selectedCountry,
        id: props.uploadedImagesArr[i].id,
        created: props.uploadedImagesArr[i].created,
        formStatus: "submitted",
      };

      let reqData = {
        action: "update-post",
        data: {
          ...formdata,
        },
      };
      let apiResponse = new Promise((resolve, reject) => {
        var request = doRequest("mkb100_2023", reqData, true);
        // resolve(request)
        request.addEventListener("load", function () {
          resolve(request.response);
          try {
            var data = JSON.parse(request.responseText);
            if (data["_resultflag"] && data["_resultflag"] === 1) {
              if (i == props.uploadedImagesArr.length - 1) {
                setLoading(false);
                setCompleted(true);
              }
            } else {
              setLoading(false);
            }
          } catch (ex) {
            alert(ex.message);
            setLoading(false);
          }
        });
      });
    }

    // console.log('formdatea', formdata)
    // props.save(formdata);
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      // alert('Geolocation is not supported by this browser.')
      console.log("Geolocation is not supported by this browser.");
    }
  };

  const showPosition = (position) => {
    // showPopup(`lat is ${position.coords.latitude} and long is ${position.coords.longitude}`)
    setLat(position.coords.latitude);
    setLong(position.coords.longitude);
  };

  const showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        console.log("User denied the request for Geolocation.");
        // showPopup("User denied the request for Geolocation.");
        setLocation_status("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        console.log("Location information is unavailable.");
        // showPopup("Location information is unavailable.");
        setLocation_status("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        console.log("The request to get user location timed out.");
        // showPopup("The request to get user location timed out.");
        setLocation_status("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        console.log("An unknown error occurred.");
        // showPopup("An unknown error occurred.");
        setLocation_status("An unknown error occurred.");
        break;
    }
  };

  const getUserInfo = async (async) => {
    var urlParams = parseURLParams(window.location.href);
    let user_id = "";

    try {
      let href = window.location.href;
      href = href.split("?userid=")[1];
      user_id = href.split("&")[0];
    } catch (error) {}

    var userid =
      urlParams && urlParams["userid"]
        ? urlParams["userid"][0]
        : user_id
        ? user_id
        : "";

    var data = {
      action: userid ? "login-user" : "unlogin-user",
    };
    if (userid) {
      data["data"] = {
        userid: userid,
      };
    }
    var request = doRequest("mkb100_2023", data, async);
    request.addEventListener("load", function () {
      try {
        var data = JSON.parse(request.responseText);

        if (data["_resultflag"] && data["_resultflag"] === 1) {
          let userData = data["user"]["data"];
          localStorage.setItem("token", data["token"]);
          localStorage.setItem("user", JSON.stringify(data["user"]["data"]));
        }
      } catch (ex) {}
      if (localStorage.getItem("user")) {
        let userdata = JSON.parse(localStorage.getItem("user"));

        let obj = {};
        obj["name"] = userdata["user_name_display"]
          ? userdata["user_name_display"]
          : "";
        obj["mobile"] = userdata["user_contact_no"]
          ? userdata["user_contact_no"].replace(/\D/g, "").slice(-10)
          : "";
        obj["state"] = userdata["state_name"] ? userdata["state_name"] : "";
        obj["constituency"] = userdata["constituency_name"]
          ? userdata["constituency_name"]
          : "";
        obj["state_id"] = userdata["state_id"] ? userdata["state_id"] : "";
        getConstituencyList(obj["state_id"]);
        setName(obj["name"]);
        setMobile(obj["mobile"]);
        setState(obj["state"]);
        setConsti(obj["constituency"]);
      }
    });
  };

  useEffect(() => {
    var urlParams = parseURLParams(window.location.href);
    let user_id = "";

    try {
      let href = window.location.href;
      href = href.split("?userid=")[1];
      user_id = href.split("&")[0];
    } catch (error) {}

    var userid =
      urlParams && urlParams["userid"]
        ? urlParams["userid"][0]
        : user_id
        ? user_id
        : "";
    if (userid) {
      // if (!localStorage.getItem("token")) {
      getUserInfo(true);
      // }
    }
  }, []);

  const getCountryCode = (e) => {
    setCountryCode(e);
    localStorage.setItem("countryCode", e);
  };

  useEffect(() => {
    try {
      let code = localStorage.getItem("countryCode");
      if (code) {
        setCountryCode(code);
      }
    } catch (error) {}
  }, []);

  useEffect(() => {
    if (selectedCountry == "India") {
      setShowStateConsti(true);
      if (
        mobile &&
        (mobile.length < 10 || mobile.length > 10)
      ) {
        setMobilelengthError(true);
        
      } else {
        setMobilelengthError(false);
      }
    } else {
      setMobilelengthError(false);
      setShowStateConsti(false);
    }
    setState("");
    setConsti("");
  
    
  }, [selectedCountry]);

  return (
    <>
      <div className="form_bg">
        <div className="photocontainer">
          <div className="form_div">
            <img
              src="./assets/images/img-1.jpg"
              className="mkb_banner2"
              alt=""
            />

            <div className="form_detail">
              <div className="formcontainer">
                <div className="form_content">
                  <h5>
                    Join us in celebrating the 100th episode of Mann ki Baat.
                  </h5>
                  <div className="form_label">
                    <label>Name</label>
                    <input
                      value={name}
                      onChange={(e) => {
                        var letters = /^[A-Za-z\s]+$/;

                        if (e.target.value == "") {
                          setName(e.target.value);
                        } else {
                          if (
                            e.target.value.match(letters) &&
                            e.target.value.length < 31
                          ) {
                            setName(e.target.value);
                          }
                        }
                      }}
                      type="text"
                      placeholder="Name"
                    />
                  </div>

                   <div className="form_label">
                    <label>Country</label>
                    <select
                      value={selectedCountry}
                      onChange={(e) => setSelectedCountry(e.target.value)}
                      className="select_state"
                    >
                      <option value="">Select Country</option>
                      {countryList &&
                        countryList?.length > 0 &&
                        countryList.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                    </select>
                    <img
                      src="./assets/images/dropdown.svg"
                      className="mkbselect"
                      alt=""
                    />
                  </div>

                  <div className="form_label">
                    <div style={{ position: "relative" }}>
                      {/* <select
                        className="phonepincode"
                        value={changecode}
                        onChange={(e) => {
                          setChangecode(e.target.value);
                          setMobilelengthError(false);
                        }}
                      >
                        <option value={"91"}>+91</option>
                        <option value="235">+235</option>
                      </select> */}
                      {/* <CoutryComponent getCountryCode={getCountryCode} /> */}
                      {/* <Countrycode countryval={countryval}/> */}
                      <label>Mobile Number</label>
                      <input
                        value={mobile}
                        onChange={(e) => {
                      
                        
                          if (e.target.value == "") {
                            setMobile(e.target.value);
                          } else {
                            if (!hasAlphabet(e.target.value)) {
                              if (selectedCountry == "India") {
                                if (e.target.value.length < 11) {
                                  setMobile(e.target.value);
                                  if (
                                    e.target.value &&
                                    (e.target.value.length < 10 ||
                                      e.target.value.length > 10)
                                  ) {
                                    setMobilelengthError(true);
                                    return false;
                                  } else {
                                    setMobilelengthError(false);
                                  }
                                }
                              } else {
                                setMobile(e.target.value);
                              }
                            }
                          }
                        }}
                        onBlur={(e) => {
                          // if (mobile) {
                          //   setPhoneError(false);
                          // } else {
                          //   setPhoneError(true);
                          //   return false;
                          // }
                          if (selectedCountry == "India") {
                            if (
                              mobile &&
                              (mobile.length < 10 || mobile.length > 10)
                            ) {
                              setMobilelengthError(true);
                              return false;
                            } else {
                              setMobilelengthError(false);
                            }
                          }
                        }}
                        type="number"
                        className="phonenumber"
                        placeholder=""
                      />
                    </div>
                    {phoneError && (
                      <p style={errorStyle}>
                        {"Please enter your Phone Number"}
                      </p>
                    )}
                    {mobilelengthError && (
                      <p style={errorStyle}>
                        {"Please enter a valid 10-digit mobile number"}
                      </p>
                    )}
                  </div>

                 
                  {showStateConsti && (
                    <>
                      <div className="form_label">
                        <label>State</label>
                        <select
                          id="state"
                          value={state}
                          className="select_state"
                          onChange={(e) => {
                            setState(e.target.value);
                            getConstituencyList();
                          }}
                        >
                          <option value="">Select State</option>
                          {stateval
                            ? stateval &&
                              stateval.map((element, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={element.statename}
                                    data-name={element.stateid}
                                    nameval={element.statename}
                                  >
                                    {element.statename}
                                  </option>
                                );
                              })
                            : ""}
                        </select>
                        <img
                          src="./assets/images/dropdown.svg"
                          className="mkbselect"
                          alt=""
                        />
                      </div>

                      <div className="form_label">
                        <label>Constituency</label>
                        <select
                          value={consti}
                          onChange={(e) => setConsti(e.target.value)}
                          className="select_state"
                        >
                          <option>{"Select Constituency"}</option>
                          {cityval
                            ? cityval &&
                              cityval.map((element, index) => {
                                return (
                                  <option key={index} value={element.boothname}>
                                    {element.boothname}
                                  </option>
                                );
                              })
                            : ""}
                        </select>
                        <img
                          src="./assets/images/dropdown.svg"
                          className="mkbselect"
                          alt=""
                        />
                      </div>
                    </>
                  )}

                  {/* <div className="form_label">
                    <label>Event Location</label>
                    <input
                      type="text"
                      className="event_input"
                      placeholder="Event"
                    />
                  </div>
                  <button className="enable_btn">
                    See steps to enable location{" "}
                    <img src="./assets/images/chevron.svg" alt="" />
                  </button> */}
                  <button
                    onClick={(e) => submitHandler(e)}
                    className="next_photo"
                  >
                    submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {completed && (
        <Thankyou
          close={props.closeThankYou}
          images={props.uploadedImagesArr}
        />
      )}
      {showPopupMessage && (
        <PopUpMessage close={closePopupMessage} message={popupMessage} />
      )}

      {loading && <Loader />}
      {/* <Popup /> */}
    </>
  );
}
