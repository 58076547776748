import React from "react";

function PopUpMessage(props) {
  return (
    <div className="error_popupbg">
      <div className="errorpopup_content">
        <h4>{props.message}</h4>

        <button
          onClick={props.close}
        >
          OK
        </button>
      </div>
    </div>
  );
}

export default PopUpMessage;
