import $ from 'jquery'

export const apiUrl = "https://campaign-api.narendramodi.in/";


// const uploadToS3 = async (file, fileKey, callback, index, path) => {
//     const d = new Date();
//     let month = d.getMonth();
//     let date = d.getDate();

//     progressLoader(20, 50);
//     // var bucketUrl = "https://s3.ap-south-1.amazonaws.com/" + bucketname + "/";
//     var originalKey = fileKey ? fileKey : `mkb100/2023/${month}/${date}.jpg`;
//     var params = {
//         Key: originalKey,
//         ContentType: file && file.type ? file.type : "image/jpeg",
//         Body: file,
//         ACL: "public-read",
//     };
//     if (!callback) {
//         callback = (err, data) => {
//             handleSave(
//                 path,
//                 MultipleFiles.length - 1 != index ? false : true,
//                 index
//             );
//             // console.log("uploaded data......", data);
//         };
//     }
//     await bucket.putObject(params, callback);
//     // resolve(1);
//     // ret = true;
//     // });

//     // return ret;

//     // alert('file uploaded on S3.')
// };

// const handleSave = (profileImage, last, index) => {
//     let alias_url = "https://campaign-images.narendramodi.in/";
//     let fileFullurl = alias_url + profileImage;
//     let avatar = "";
//     let user_id = "";
//     let user_name = "";

//     var urlParams = parseURLParams(window.location.href);

//     try {
//         let user = localStorage.getItem("user");
//         user = JSON.parse(user);
//         user_id = user.userid;
//         user_name = user.user_name_display;
//         avatar = user.user_avatar;
//     } catch (error) { }

//     let files = MultipleFiles;

//     let pathary = [];

//     for (let index = 0; index < files.length; index++) {
//         const element = files[index];

//         pathary.push("https://campaign-images.narendramodi.in/" + element.path);
//     }

//     // let allFilesPath = pathary.join(",");

//     let cat =
//         urlParams && urlParams["category_hashtag"]
//             ? urlParams["category_hashtag"][0]
//             : "";
//     cat = cat ? cat.split("?")[0] : ""; // this for  remove ?userid

//     let obj = {
//         name: user_name,
//         mobile: user_mobile,
//         state: urlParams && urlParams["state"] ? urlParams["state"][0] : "",
//         constituency:
//             urlParams && urlParams["constituency"]
//                 ? urlParams["constituency"][0]
//                 : "",
//         imageUrl: fileFullurl,
//         lat: "",
//         "long": "",
//         "device_id": "dgf5dgdfg5dgdfg",
//         "platform": "android"
//     };

//     var data = {
//         action: "save",
//         token: localStorage.getItem("token"),
//         // token:
//         // "U2FsdGVkX1+P+bOPZMD4oKMAeOiIPYomTTPHn9wVqM5+LLJmNBMoyBVD+Dx8SekHXn5Q3KOFP6KaWwxoPWtKj3ruEyvIYbg7H68A2EHmK0UgLcZeyh6pGGaL3x74lmEg05Qd8WwhNZe+fYYE8dKSRd58BPx/eAO+Gao0tQ3qxFT+9uUNEq0bcsYY6Gteg+S/9O7aCM7gciolzxbWGlbmrpV3hzFtgzDGXMl1YAIxhSzcaDL49BfsorgSOn6jOWPEnmuATLGK+GvWkkmY/Vj2U9hKNR3GRjwo8a8TuLYSVAhc0ZdMWM7Uo+mUu6GtgEzlEl2pFYr/DzP7dlSSD0bNzM+cm85n9RP925mfAtLV9GObFJjl09/U63ti0ZBwv41xgKrQk6IUsye1i2ydexIqAPyt1ktMUh/NiiKqEsCdfCJSSaAhhyV/27rrGvcOHy2u8tG1o48zFNMMxN1iPacxNx/C7otvDhUUYh4/Knf8CR5yQjEuE0GNYgIt0kx1wPwmvbz7c/oKK9MnjNPTTsSMN2qWIZq7BfQb8mHgmm0y6w24s54SwB+6OeLPi29NqjOzeZi23XAh6dYTGxj9GBDZT3bCR4LHM8Kt1sd0SksOa0u+CCoV18QJxg6LS5MSXor56JRP5Bj8y0tpUn85jIusSxgDCnaCalJQDXWr049wx0KQUbBV4zHvP+JjNc1ZsRi6DEZzjRdOIHBR4gXK171xCcXCWxBjtECwb/Z7TWA4LNZcZO6LdhoUIWTB5olUJjP1k3JdGyOiaHLhW0Z+dyrn95+7BJXa/zWh65GrF9MgfuaKJ0pdVPzpfYiQG1lpXl2M51Oupz8fmv8sDP5w1LDdx4VFMRIXOjiHmG62yVKkhY3uzd5KyQKOe/NpwtgWaT9OItvtqktbym88+T7HRnnenTNeeOclU0M0IeguakyaA4SPTqtrTUnEYoToO70ege//GRdB/2N9DLhkRufKnug68l2t8Vj1qBD2AxnjirkD3oY5EOnT/fdJmF6iERxN+DbQ2AxjUK6Dyl9uSyiuv4z8WS+7HHq9UYF3O2z1lH0yF/cuU3ODRWKNlYywmfepFsMRm78dMGvZ+7YsnpKJe7IxmTamzoVTgLT9ohdgl5FaT7FudWE+4h8Dlrxz/25aeFwlT5Zttkx1IdOe7C5VZJVFIDrGC2lKWg3eJW987gT8l/Y279KaW6etS+wv1/C7xFNOO07fnux6y01+kQh6puyIg3hhKK/03FOVdIXC7mBtrIrQ/AGiTSnFOltXpzJMyFzDlv1z36rpcF3VrGRDXO8o7h3DwWBTwE8ghv8sYZdrjy38GTaHNQp96dAD6N6xsMqK5oxpklz8xNA2RtHAN1wNzdkFfFrG0tXGYZRdE/riEo+/TsewRvfW1mX0nQTBAOKoughfrVyrZej+XQ1suVDnxxkKmgxbiPvF/X2O8RkooprDl9v7KXXfO8uEAcWAQne2w2LwcOYXKzFpu5UAYHn8o6VoBVl31fWVRTcHaICHncqZNaW35AmePGrcmZaKST1KpEFsI1dDDBcvrmxUWJymO5kEWnkVOfR3sB/TQOLQeQwqjsjAlzHnq7e6Es4Va0e2rcH77sjSgGmLcEipSMXnvqrietucGvCzhvf0qFKlNwOE3Hnn9fWwEv5Zr7+hjlNwxNb2SHmcle+nxol89Kgt81sUajUcHl+noIJSuB5c7iN2ZI7lAUI20UqBx28L8h66BeVn59b0C7RQ3VKSNGsZpRLovsuhJuim/SlFeH9opCpnOgjJxg9PYv1gYst7Zoi905BgcfzabWsLQ3D+DWygyzBdk5luceGv6hi4Xsla7tAxK++/hASvQld/Npc3l+5t",
//     };
//     data["data"] = obj;

//     // setLoader(true);
//     if (index == 0) {
//         progressLoader(51, 100);
//     }
//     // alert(cat);

//     var request = doRequest("my_sewa_2022", data, true);
//     request.addEventListener("load", function () {
//         try {
//             var data = JSON.parse(request.responseText);
//             // alert(JSON.stringify(data));
//             if (data["_resultflag"] && data["_resultflag"] === 1) {
//                 try {
//                     let obj = {};
//                     window.gtag("event", "save picture event", obj);
//                 } catch (error) { }
//                 if (localStorage.getItem("riltoken")) {
//                     // alert(cat);
//                     // RilApiForLeaderboard(data.id, cat);
//                 }
//                 if (last) {
//                     setTimeout(() => {
//                         if (photoSelected) {
//                             setPhotoSelected(false);
//                         }
//                         setCompleted(true);

//                         if (camera) {
//                             setCamera(false);
//                         }
//                         setLoader(false);
//                     }, 1000);
//                 }
//             } else {
//                 setLoader(false);
//             }
//         } catch (ex) {
//             alert(ex.message);
//             setLoader(false);
//         }
//     });
// };

export const AjaxRequest = async (reqData) => {
  let Data = {
    token: "gKKZLKeV+7Qz8UpLR2loJ6/v0KyVSqbs+DI1KREOIOTkHwGWRvficfOsUa1joUPu",
    deviceid: "ffffffff-cb11-0cb1-ffff-ffffd3f1ea4a",
  };
  Data = { ...Data, ...reqData };
  var settings = {
    url: "https://api.narendramodi.in/mlapiv1",
    method: "POST",
    timeout: 0,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      // "Cookie": "BJP-LB=APP02"
    },
    data: Data,
  };

  let data = await $.ajax(settings);

  return data;
};

const handleEvent = (evt) => {
  var percentComplete = (evt.loaded / evt.total) * 100;
};

export const AjaxRequestForConstituency = async (reqData) => {
  let Data = {
    token: "gKKZLKeV+7Qz8UpLR2loJ6/v0KyVSqbs+DI1KREOIOTkHwGWRvficfOsUa1joUPu",
    deviceid: "ffffffff-cb11-0cb1-ffff-ffffd3f1ea4a",
  };
  Data = { ...Data, ...reqData };
  var settings = {
    url: "https://api.narendramodi.in/apiv2",
    method: "POST",
    timeout: 0,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      // "Cookie": "BJP-LB=APP02"
    },
    data: Data,
  };

  let data = await $.ajax(settings);

  return data;
};

export const doRequest = (path, payload, sync) => {
  var data = JSON.stringify(payload);
  var xhr = new XMLHttpRequest();
  xhr.open("POST", apiUrl + path, sync);
  // xhr.setRequestHeader( "content-type", "application/json" );
  xhr.onprogress = handleEvent;
  xhr.send(data);
  return xhr;
};


export const parseURLParams = (url) => {
  var queryStart = url.indexOf("?") + 1,
    queryEnd = url.indexOf("#") + 1 || url.length + 1,
    query = url.slice(queryStart, queryEnd - 1),
    pairs = query.replace(/\+/g, " ").split("&"),
    parms = {},
    i,
    n,
    v,
    nv;

  if (query === url || query === "") return;

  for (i = 0; i < pairs.length; i++) {
    nv = pairs[i].split("=", 2);
    n = decodeURIComponent(nv[0]);
    v = decodeURIComponent(nv[1]);

    if (!parms.hasOwnProperty(n)) parms[n] = [];
    parms[n].push(nv.length === 2 ? v : null);
  }
  return parms;
};

export const countryList = [
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Plurinational State of Bolivia",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos Keeling Islands",
  "Colombia",
  "Comoros",
  "The Democratic Republic of Congo",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Islamic Republic of Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "The Democratic People's Republic of Korea",
  "The Republic of Korea",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of North Macedonia",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin",
  "Saint Pierre and Miquelon",
  "Saint Vincent and Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom of Great Britain and Northern Ireland",
  "United States Minor Outlying Islands",
  "United States of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Viet Nam",
  "Virgin Islands",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe"
]; 