import logo from "./logo.svg";
import "./App.css";
import PhotoUpload from "./PhotoUpload";
import Form from "./Form";
import Popup from "./Popup";
import { useState, useEffect } from "react";
import PopUpMessage from "./PopupMessage";
import Thankyou from "./Thankyou";
import { doRequest, parseURLParams } from "./helper";

import AWS from "aws-sdk/global";
import S3 from "aws-sdk/clients/s3";
import Gallery from "./Gallery";
import CoutryComponent from "./CountryComponent";
import Loader from "./Loader";
import { v4 as uuidv4 } from "uuid";
import $ from "jquery";

import imageCompression from "browser-image-compression";

// import { getCountries } from 'libphonenumber-js';

// const countries = getCountries();

// console.log(countries.map(country => country.isoCode))

const bucketname = "js-sdk-bucket";
AWS.config.region = "ap-south-1"; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: "ap-south-1:84027705-475d-4ca1-bac8-b2eb7407ae9f",
});
const bucket = new AWS.S3({
  params: {
    Bucket: bucketname,
  },
});

function App() {
  const [file, setFile] = useState("");
  const [showPopupMessage, setShowPopupMessage] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [MultipleFiles, setMultipleFiles] = useState([]);
  const [photoArr, setPhotoArr] = useState([]);
  const [photoSelected, setPhotoSelected] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [loader, setLoader] = useState(false);
  const [camera, setCamera] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [showFormPage, setShowFormPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadedImgId, setUploadedImgId] = useState([]);
  const [loadingText, setLoadingText] = useState("");

  let uploadedImagesArr = [];

  let i = 0;
  const startLoading = (text) => {
    setLoadingText(text);
    setLoading(true);
  };
  const endLoading = () => {
    setLoadingText("");
    setLoading(false);
  };

  const falsePhotoSelected = () => setPhotoSelected(false);

  const closePopupMessage = () => setShowPopupMessage(false);
  const showPopup = (msg) => {
    setShowPopupMessage(true);
    setPopupMessage(msg);
  };

  const uploadImages = async (allphotos) => {
    setLoading(true);
    startLoading("Processing Images");

    // e.preventDefault();
    // let files = e.target.files;
    // if (files.length > 25) {
    //   showPopup("You can upload up to 25 images at once");
    //   return false;
    // }
    var sessionid = Date.now();
    let newDate = new Date();
    let month = newDate.getMonth();
    let date = newDate.getDate();
    let ary = [];
    let photosArray = [];
    if (allphotos) {
      for (let index = 0; index < allphotos.length; index++) {
        if (
          allphotos[index].type != "image/jpeg" &&
          allphotos[index].type != "image/png" &&
          allphotos[index].type != "image/jpg"
        ) {
          showPopup("You can upload images only.");
          setLoading(false);
          break;
        }
        try {
          const options = {
            maxSizeMB: 2,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          };
          const compressedFile = await imageCompression(
            allphotos[index],
            options
          );
          // console.log("compressedfile", compressedFile);
          var element = new File([compressedFile], compressedFile.name, {
            lastModified: compressedFile.lastModified,
            type: allphotos[index].type,
          });

          // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
          // console.log('compressedFile instanceof Blob', compressedFile); // true
          // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

          // await uploadToServer(compressedFile); // write your own logic
          // console.log("element is ", element, "allphotos", allphotos[index]);

          // const element = allphotos[index];
          let extension = element.type.split("/");
          extension = extension[extension.length - 1];
          // console.log(extension);

          if (
            element.type != "image/jpeg" &&
            element.type != "image/png" &&
            element.type != "image/jpg"
          ) {
            showPopup("You can upload images only.");
            setLoading(false);
            // allphotos = ''
            return false;
          }
          let random = Math.floor(100000 + Math.random() * 900000);

          ary.push({
            // path: `mkb100/${sessionid}_${random}_${index}.${extension}`,
            path: `mkb100/2023/${
              month + 1
            }/${date}/${sessionid}_${random}_${index}.${extension}`,
            file: element,
          });
          photosArray.push(element);
        } catch (error) {
          console.log(error);
        }
      }

      // console.log("ary is", ary);
      // console.log("photosary is", photosArray);

      setLoading(false);
      endLoading();
      setMultipleFiles(ary);
      setPhotoArr(photosArray);
      // let profile_image = `my_sewa/${sessionid}.jpg`;
      // setProfileImage(profile_image);
      const [file] = allphotos;
      setFile(URL.createObjectURL(file));
      setPhotoSelected(true);
      setSelectedFile(allphotos);
    } else {
      // console.log("e is ", e.value);
    }
  };

  const handleSave = async (profileImage, last, index, formdata) => {
    let alias_url = "https://campaign-images.narendramodi.in/";
    let fileFullurl = alias_url + profileImage;
    let avatar = "";
    let user_id = "";
    let user_name = "";

    var urlParams = parseURLParams(window.location.href);

    try {
      let user = localStorage.getItem("user");
      user = JSON.parse(user);
      user_id = user.userid;
      user_name = user.user_name_display;
      avatar = user.user_avatar;
    } catch (error) {}

    let files = MultipleFiles;

    let pathary = [];

    for (let index = 0; index < files.length; index++) {
      const element = files[index];

      pathary.push("https://campaign-images.narendramodi.in/" + element.path);
    }
    let cat =
      urlParams && urlParams["category_hashtag"]
        ? urlParams["category_hashtag"][0]
        : "";
    cat = cat ? cat.split("?")[0] : ""; // this for  remove ?userid

    let reqData = {
      action: "save",
      data: {
        formStatus: "not submitted",
        imageUrl: fileFullurl,
        device_id: localStorage.getItem("device_id"),
        platform:
          localStorage.getItem("appversion") != "null"
            ? localStorage.getItem("appversion")
            : "pwa",
      },
    };
    // return false;
    var request = await new Promise((resolve, reject) => {
      var response = doRequest("mkb100_2023", reqData, true);
      // resolve(response)
      response.addEventListener("load", function () {
        resolve(response.response);
        try {
          var data = JSON.parse(response.responseText);
          if (data["_resultflag"] && data["_resultflag"] === 1) {
            // uploadedImagesArr.push({ id: data.id, created: data.data.created });

            // console.log(last, "lastlastlast");

            // console.log(i++, "jhgjgj");
            // if(i++ == MultipleFiles.length -1){

            // }
            if (i++ == MultipleFiles.length - 1) {
              try {
                let obj = {};
                window.gtag("event", "Form filled successfully", obj);
              } catch (error) {}
              // setTimeout(() => {
              if (photoSelected) {
                setPhotoSelected(false);
              }
              setShowFormPage(true);
              // setCompleted(true);
              // if (showFormPage) {
              //   setShowFormPage(false)
              // }

              if (camera) {
                setCamera(false);
              }
              setLoading(false);
              endLoading();
              setLoader(false);
              // }, 0);
            }
          } else {
            setLoader(false);
          }
        } catch (ex) {
          alert(ex.message);
          setLoader(false);
        }
      });
    });

    // setLoading(false);
    return request;

    console.log("request", request);
  };

  const uploadToS3 = async (file, fileKey, callback, index, path, formdata) => {
    const d = new Date();
    let month = d.getMonth();
    let date = d.getDate();

    // progressLoader(20, 50);
    var originalKey = fileKey ? fileKey : `mkb100/2023/${month}/${date}.jpg`;
    var params = {
      Key: originalKey,
      ContentType: file && file.type ? file.type : "image/jpeg",
      Body: file,
      ACL: "public-read",
    };

    if (!callback) {
      callback = async (err, data) => {
        let res = await handleSave(
          path,
          MultipleFiles.length - 1 != index ? false : true,
          index,
          formdata
        );
        // console.log("handlesave commented");
        // console.log(i++, "jhgjgj");

        var data = JSON.parse(res);
        uploadedImagesArr.push({ id: data.id, created: data.data.created });

        setUploadedImgId(uploadedImagesArr);

        // return res;
      };
    }

    bucket
      .putObject(params)
      .promise()
      .then(async function (rdata) {
        let res = await handleSave(
          path,
          MultipleFiles.length - 1 != index ? false : true,
          index,
          formdata
        );
        // console.log("handlesave commented");

        var data = JSON.parse(res);
        uploadedImagesArr.push({ id: data.id, created: data.data.created });

        setUploadedImgId(uploadedImagesArr);
        console.log("Upload succeeded:", data);
      })
      .catch(function (err) {
        console.error("Upload failed:", err);
      });
    // await bucket.putObject(params, callback);
  };

  const save = async (formdata) => {
    startLoading("Uploading Images");
    uploadedImagesArr = [];
    // setPhotoSelected(false);
    setLoader(true);
    // progressLoader(1, 20);
    let file = "";
    if (selectedFile.files) {
      file = selectedFile.files[0];
    } else {
      file = selectedFile[0];
    }

    let files = MultipleFiles;
    setLoading(true);
    for (let index = 0; index < files.length; index++) {
      const element = files[index];
      let data = await uploadToS3(
        element.file,
        element.path,
        "",
        index,
        element.path,
        formdata
      );

      // console.log(data, "resresres");
    }
  };

  const updateSelectedPhotos = (index) => {
    let arr = [...photoArr];
    arr.splice(index, 1);
    setPhotoArr(arr);
    var sessionid = Date.now();
    let newDate = new Date();
    let month = newDate.getMonth();
    let date = newDate.getDate();
    let random = Math.floor(100000 + Math.random() * 900000);
    let ary = [];

    if (arr) {
      for (let index = 0; index < arr.length; index++) {
        const element = arr[index];

        ary.push({
          path: `mkb100/2023/${
            month + 1
          }/${date}/${sessionid}_${random}_${index}.jpg`,
          file: element,
        });
      }
      // console.log(ary, "ary");
      setMultipleFiles(ary);
    }
  };

  const showForm = () => {
    // setShowFormPage(true);
    setPhotoSelected(false);
    save();
  };

  const closeThankYou = () => {
    setCompleted(false);
    setShowFormPage(false);
  };

  useEffect(() => {
    var url_string = window.location.href; //window.location.href
    var url = new URL(url_string);
    var userid = url.searchParams.get("userid");

    let androidORIos = "pwa";
    if (url.searchParams.get("appversionios")) {
      androidORIos = "ios";
    } else if (url.searchParams.get("appversionandroid")) {
       androidORIos = "android";
    }

    // console.log(androidORIos)

    // return

    // var androidORIos = url.searchParams.get("appversion");
    let localDeviceId = localStorage.getItem("device_id");
    let newDeviceId = uuidv4();
    localStorage.setItem(
      "device_id",
      userid ? userid : localDeviceId ? localDeviceId : newDeviceId
    );
    localStorage.setItem("appversion", androidORIos);
  }, []);

  return (
    <>
      <div className="desk_center">
        {!photoSelected && !completed && !showFormPage && (
          <PhotoUpload showPopup={showPopup} uploadImages={uploadImages} />
        )}
        {showFormPage && (
          <Form
            uploadedImagesArr={uploadedImgId}
            completed={completed}
            closeThankYou={closeThankYou}
            loading={loading}
            selectedPhotos={MultipleFiles}
          />
        )}
        {photoSelected && (
          <Gallery
            showForm={showForm}
            image={file}
            falsePhotoSelected={falsePhotoSelected}
            save={save}
            allPhotosSelected={photoArr}
            updateSelectedPhotos={updateSelectedPhotos}
          />
        )}
        {/* <Popup /> */}
        {showPopupMessage && (
          <PopUpMessage close={closePopupMessage} message={popupMessage} />
        )}
        {/* {completed && <Thankyou close={closeThankYou} />} */}
        {loading && <Loader text={loadingText} />}
      </div>
    </>
  );
}

export default App;
